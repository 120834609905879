import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["controller", "receiver"];
  static values = {
    attr: String,
  };

  validate() {
    const controller = this.controllerTarget;
    if (controller.type !== "checkbox") {
      console.warn("Controller should always be a checkbox!");
      return;
    }

    const receiver = this.receiverTarget;

    if (controller.checked) {
      receiver.removeAttribute(this.attrValue);
    } else {
      receiver.setAttribute(this.attrValue, this.attrValue);
    }
  }
}
