import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  copyToClipboard(e) {
    e.preventDefault();
    if (this.element.hasAttribute("value")) {
      navigator.clipboard.writeText(this.element.getAttribute("value"));
      const icon = this.element.querySelector("rmv-icon");
      if (icon) {
        icon.setAttribute("name", "check-lg");
        setTimeout(() => {
          icon.setAttribute("name", "files");
        }, 500);
      }
    }
  }
}
