import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selection"];

  connect() {}

  enable() {
    this.selectionTarget
      .querySelector("rmv-select")
      .setAttribute("disabled", "true");
  }

  disable() {
    this.selectionTarget
      .querySelector("rmv-select")
      .removeAttribute("disabled");
  }
}
