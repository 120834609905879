import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static targets = ["element", "filtrand", "empty"];
  static debounces = ["search"];

  connect() {
    useDebounce(this, { wait: 300 });
  }

  search(event) {
    if (!this.hasElementTarget) {
      console.warn("No element or search target found!");
      return;
    }
    const searchTerm = event.target.value.toLowerCase();

    const elements = this.elementTargets; // All the items to be filtered
    const filtrands = this.filtrandTargets; // All the items to filter by
    const numberOfElementsToHide =
      this.elementTargets.length / this.filtrandTargets.length; // Number of elements to hide per filtrand

    // Loop over each filtrand and its associated elements
    filtrands.forEach((filtrand, i) => {
      // Get the plain text content of the filtrand, ignoring any HTML tags
      let textValues = filtrand.textContent.toLowerCase().trim();
      // Determine if the filtrand text contains the search term
      let containsSearchTerm = textValues.includes(searchTerm);
      // Calculate the start index for the associated elements for this filtrand
      let startIndex = i * numberOfElementsToHide;

      // Loop over each associated element for this filtrand
      for (let j = 0; j < numberOfElementsToHide; j++) {
        let elementIndex = startIndex + j;
        if (elements[elementIndex]) {
          // Show or hide the corresponding element
          elements[elementIndex].hidden = !containsSearchTerm;
        }
      }
    });

    if (this.hasEmptyTarget) {
      const visibleRows = filtrands.filter((row) => !row.hidden);

      const visibleRowCount = visibleRows.length;
      if (visibleRowCount === 0) {
        this.emptyTarget.hidden = false;
      } else {
        this.emptyTarget.hidden = true;
      }
    }
  }
}
