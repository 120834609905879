import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["key", "input", "button"];

  regenerateApiKey() {
    // Generate a random hexadecimal string (example: 32 characters long)
    const newKey = [...Array(32)]
      .map(() => Math.floor(Math.random() * 32).toString(32))
      .join("");
    const displayInput = this.keyTarget;
    if (displayInput) {
      displayInput.value = newKey;
    }
    const submissionInput = this.inputTarget;
    if (submissionInput) {
      submissionInput.value = newKey;
    }
    const copyButton = this.buttonTarget;
    if (copyButton) {
      copyButton.setAttribute("value", newKey);
    }
  }
}
