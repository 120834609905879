import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = {
    modalUrl: String,
  };

  setSelected(event) {
    this.removeSelected();
    const button = event.target.closest("rmv-button");
    button.selected = true;
  }

  removeSelected() {
    const buttons = document.querySelectorAll("rmv-button");
    buttons.forEach((button) => {
      button.selected = false;
    });
  }

  import(event) {
    get(this.modalUrlValue, {
      responseKind: "turbo-stream",
    });
  }
}
