//overwrite data-turbo-confirm modal
const newConfirmMethod = async (message, formElement) => {
  const modal = document.getElementById("confirmation-modal");
  if (!modal) return;

  const title = modal.querySelector(
    '[data-selector="confirmation-modal-title"]'
  );
  const content = modal.querySelector(
    '[data-selector="confirmation-modal-content"]'
  );

  const cancelBtn = modal.querySelector(
    '[event-id="confirmation-modal-cancel"]'
  );

  const confirmBtn = modal.querySelector(
    '[event-id="confirmation-modal-confirm"]'
  );
  const strings = JSON.parse(message);
  title.textContent = strings.title;
  content.textContent = strings.description;
  cancelBtn && cancelBtn.setAttribute("value", strings.cancel);
  confirmBtn && confirmBtn.setAttribute("value", strings.confirm);
  modal.open = true;
  return new Promise((resolve, reject) => {
    document.addEventListener(
      `rmv-button:click:confirmation-modal-close`,
      () => {
        modal.open = false;
        resolve(false);
      }
    );
    document.addEventListener(
      `rmv-button:click:confirmation-modal-cancel`,
      () => {
        modal.open = false;
        resolve(false);
      }
    );
    document.addEventListener(
      `rmv-button:click:confirmation-modal-confirm`,
      () => {
        modal.open = false;
        resolve(true);
      }
    );
  });
};
export { newConfirmMethod };

Turbo.setConfirmMethod(newConfirmMethod);
