import { DirectUpload } from "@rails/activestorage";
import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { put } from "@rails/request.js";

let fileCount = 0;

const fileTypes = {
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
};

const sizeFormatter = (size) => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    parseInt(size / Math.pow(1024, i)) + " " + ["B", "KB", "MB", "GB", "TB"][i]
  );
};

export default class extends Controller {
  uploadFiles(e) {
    if (!e.target.files) return;
    fileCount = e.target.files.length;
    const name = e.target.name.replace(
      "{timestamp}",
      new Date().getTime().toString()
    );
    const url = e.params.url;
    const form = e.target.closest("rmv-modal")?.getElementsByTagName("form")[0];
    Array.from(e.target.files).forEach((file) => {
      this.uploadFile(file, name, url, form, e.params.validationUrl);
    });
    e.target.value = null;
  }

  clear() {
    const dashboardUpload = document.getElementById("dashboard-upload");
    dashboardUpload.value = null;
    dashboardUpload.setAttribute("files", null);
    dashboardUpload.setAttribute("text", "Drag and drop documents here");
    dashboardUpload.setAttribute("button-text", "Or choose files");
  }

  uploadFile(file, name, url, form, validationUrl) {
    const upload = new DirectUpload(file, url, {
      directUploadWillStoreFileWithXHR: this.processUpload.bind(this, file),
    });

    if (form) {
      // this block makes it seem that the files are immediately uploaded
      const fileTemplate = this.cardTemplate(file);
      const card = document.createElement("rmv-column");
      card.setAttribute("padding", "8/16");
      card.innerHTML = fileTemplate;
      form
        .closest("rmv-modal")
        .getElementsByTagName("rmv-scrollable")[0]
        .appendChild(card);
    }

    upload.create((error, blob) => {
      fileCount--; // fileCount decreases before the early return of the error, to ensure the count is still correct even though an error has happened
      if (error) {
        console.log("UPLOAD ERROR: ", error);
        return;
      }
      if (form) {
        //  Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("hidden", "true");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = name;
        form.appendChild(hiddenField);
      }
      post(validationUrl, {
        body: JSON.stringify({
          signed_id: blob.signed_id,
          render_modal: fileCount === 0,
        }),
        responseKind: "turbo-stream",
      });
    });
  }

  cardTemplate(file) {
    return `
    <rmv-file 
      description="${file?.name?.split(".")[1]?.toUpperCase()} / ${(
      file?.size / 1024
    ).toFixed(2)} KB"
      icon="file-earmark" 
      title="${file.name}">
      </rmv-file>`;
  }

  processUpload(file, request) {
    request.upload.addEventListener("progress", (event) => {
      this.directUploadDidProgress(event, file);
    });
  }

  directUploadDidProgress(event, file) {
    const card = document.getElementById(`${file.name}-${file.size}`);
    // const progress = card.querySelector("rmv-progress");
    // progress.setAttribute(
    //   "value",
    //   parseInt((event.loaded / event.total) * 100)
    // );
  }

  validateRiskFactor(event) {
    put(event.params.validationUrl, {
      body: JSON.stringify({
        risk_factor_id: event.target.value,
      }),
      responseKind: "turbo-stream",
    });
  }
}
