import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  connect() {
    this.ensureSelectedVisible();
  }

  getRelativeTop(child) {
    const childRect = child.getBoundingClientRect();
    const parentRect = child.parentNode.getBoundingClientRect();
    return childRect.top - parentRect.top;
  }

  ensureSelectedVisible() {
    if (this.hasElementTarget) {
      requestAnimationFrame(() => {
        const target = this.elementTarget;
        const relativeTop = this.getRelativeTop(target);
        const centeredScrollTop =
          relativeTop - this.element.height / 2 + target.clientHeight / 2;
        this.element.scrollTop = centeredScrollTop;
      });
    } else {
      console.error("No element target found!");
    }
  }
}
