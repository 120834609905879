import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selection"];

  connect() {
    this.hide();
  }

  show() {
    this.selectionTarget.style.display = "contents";
  }

  hide() {
    this.selectionTarget.style.display = "none";
  }
}
