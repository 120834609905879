import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["actions"];
  toggle(e) {
    const accordion = e.target;
    const isOpen = accordion.open;
    if (isOpen) {
      this.actionsTarget.hidden = false;
      this.element.color = "light";
    } else {
      this.actionsTarget.hidden = true;
      this.element.color = "balanced-100";
    }
  }
}
