import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["setVersionId", "riskFactors", "products"];

  emptySetVersion() {
    this.setVersionIdTarget.value = "";
  }

  emptyRiskFactors() {
    this.riskFactorsTarget.value = "";
  }

  emptyProducts() {
    this.productsTarget.value = "";
  }
}
