import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row"];
  static values = {
    parent: String,
  };

  //toggle visibility of rows connected by shared data-index attribute
  //also toggles the icon of the trigger
  toggleRow(e) {
    const target = e.currentTarget;
    const index = e.currentTarget.dataset.index;
    const tr = this.rowTargets.filter((r) => r.dataset.index == index);
    if (tr.length > 0) {
      tr.forEach((row) => {
        const iconComponent = target.querySelector("rmv-icon");
        const component = row.closest("rmv-table-row");
        if (component.hasAttribute("hidden")) {
          iconComponent.setAttribute("name", "chevron-down-small");
          iconComponent.setAttribute("color", "energized-500");
          component.removeAttribute("hidden");
        } else {
          iconComponent.setAttribute("name", "chevron-right-small");
          iconComponent.setAttribute("color", "balanced-500");
          component.setAttribute("hidden", true);
        }
      });
    }
    //remove blur from active element
    document.activeElement.blur();
  }
}
