import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  close(e) {
    e?.preventDefault();
    const snackbar = this.element.closest("rmv-snackbar");
    snackbar.remove();
  }

  connect() {
    setTimeout(() => {
      this.close(null);
    }, 5000);
  }
}
