import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card"];

  // This controller will most likely be removed once the new vanilla structure
  // has been implemented, making it redundant.
  // https://remarkgroup.atlassian.net/browse/RAS-344
  connect() {
    const styles = {
      position: "absolute",
      top: "50px",
      "z-index": "99",
      width: "250px",
      right: "0",
      display: "none",
    };

    setTimeout(() => {
      if (this.hasCardTarget) {
        const styleString = Object.keys(styles)
          .map((key) => `${key}: ${styles[key]}`)
          .join("; ");
        this.cardTarget.style.cssText = styleString;
      }
    }, 1);
  }
  toggle() {
    if (this.cardTarget.style.display === "none") {
      this.cardTarget.style.display = "block";
    } else {
      this.cardTarget.style.display = "none";
    }
  }
}
